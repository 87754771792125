import React, {Fragment} from 'react'

import "./HerderFormulario.css"

const HerderFormulario = ( props ) => {
    return ( 
        <Fragment>
            {"Regístrate a nuestra boda."}
            <span style={{ float: "right" }} onClick={() => props.modalCancel()}>X</span>
        </Fragment>
    );
}
 
export default HerderFormulario;
