import React, {Fragment} from 'react'

import './UsuarioRegistrado.css'

const UsuarioRegistrado = () => {
    return ( 
        <Fragment>
            <button className="btn btn-outline-success">Registro exitoso</button>
            {/* <object id="textoRegistroOk" type="image/svg+xml" data="./img/Confirmar_ok.svg"></object> */}
        </Fragment>
     );
}
 
export default UsuarioRegistrado;