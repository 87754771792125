import React, {Fragment} from 'react';
import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_4/data.json';

import ConfirmarAsistenciaBoton from '../../components/ConfirmarAsistenciaBoton/ConfirmarAsistenciaBoton';

// Estilo
import "./Seccion_4.css"

const Seccion_4 = (props) => {

    let styleImage = {};

    if (props.ancho > 600) {
      styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 300%)",
        zIndex: "-100"
        };
    } else {
      styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        width: "100vw",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 300%)",
        zIndex: "-100"
        };
    }

    const style = {
        top: 0,
        margin: '-1px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        transform: "translate(0%, 0%)",
      };  

      const interactivitySeccion4 = {
        mode: "scroll",
        actions: [
            {
                visibility: [0, 0.35],
                type: "stop",
                frames: [0],
            },
            {
                visibility: [0.35, 0.45],
                type: "play",
                frames: [0, 65],
            }
        ],
    };

    return (
        <Fragment>
            <Lottie 
                loop="false"
                autoplay
                animationData={animationData}
                style={style}
            />
            <img id="fonddoSeccion4" src="./img/fondo2.jpg" style={styleImage}></img>

            {/* <object id="textoFiesta" type="image/svg+xml" data="./img/Fiesta.svg"></object> */}

            <div id="textoAsistencia">
                  <img src="./img/Asistencia.svg" alt=""/>
                  <div id="ConfirmarAsistenciaBoton" >
                    <ConfirmarAsistenciaBoton/>
                  </div>
            </div> 

        </Fragment>

    );
}
 
export default Seccion_4