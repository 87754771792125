import React, {useRef, useEffect, useState, Fragment} from 'react'
import { useNavigate, useLocation } from "react-router-dom";

import "./LoginPersonalizado.css"

import musica from '../Login/musica.mp3'

const  LoginPersonalizado = (props) => {
    let {search} = useLocation();
    let query = new URLSearchParams(search);
    
    const navigate = useNavigate();
    const imgSwipeUpRef = useRef(null);
    const cajaTransparenteRef = useRef(null);

    const [altura, setAltura] = useState(0)
    const [ancho, setAncho]   = useState(0)
  
    useEffect(() => {
        setAltura( window.innerHeight )
        const anchoAux = (window.innerHeight * 57) / 100
        setAncho( anchoAux )
        console.log( "altura: " + altura + "ancho: " + ancho  )
    }, [altura, ancho])

   // use Audio constructor to create HTMLAudioElement
    const audioTune = new Audio(musica);
    
    // variable to play audio in loop
    const [playInLoop, setPlayInLoop] = useState(true);
    
    // load audio file on component load
    useEffect(() => {
        audioTune.load();
    }, [])
    
    // set the loop of audio tune
    useEffect(() => {
        audioTune.loop = playInLoop;
    }, [playInLoop])
    
    // play audio sound
    const playSound = () => {
        imgSwipeUpRef.current.hidden = "true";
        cajaTransparenteRef.current.hidden = "true";
        audioTune.play();
        console.log("play");
        query.get("mkt") === null ? navigate(`/t`) :  navigate(`/t?mkt=${1}`);
    }
    
    // pause audio sound
    const pauseSound = () => {
        audioTune.pause();
        console.log("stop");
    }
    
    // stop audio sound
    const stopSound = () => {
        audioTune.pause();
        audioTune.currentTime = 0;
        console.log("pause");
    }

    return ( 
        <Fragment>
                <div id="LoginPersonalizado" onClick={playSound} ref={cajaTransparenteRef}>
                    {query.get("t1") === null ? "" :  <h1 className='t1' content="notranslate"> {query.get('t1')} </h1> }
                    {query.get("t2") === null ? "" :  <h1 className='t2' content="notranslate"> {query.get('t2')} </h1> }
                    {query.get("t3") === null ? "" :  <h1 className='t3' content="notranslate"> {query.get('t3')} </h1> }
                    <img id="swipeUp" src="./img/PantallaClickPersonalizado.svg" ref={ imgSwipeUpRef } alt=""></img>
                </div>      
        </Fragment>                  
    );



}
 
export default LoginPersonalizado;