import React, {Fragment} from 'react';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_5/data.json';
import animationFondo from '../../data/Seccion_5_Fondo/data.json';

import ConfirmarAsistenciaBoton from '../../components/ConfirmarAsistenciaBoton/ConfirmarAsistenciaBoton';

import "./Seccion_5.css"

const Seccion_5 = (props) => {

let styleImage = {};

    if (props.ancho > 600) {
      styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 400%)",
        zIndex: "-100"
        };
    } else {
      styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        width: "100vw",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 400%)",
        zIndex: "-100"
        };
    }

    const style = {
        top: 0,
        margin: '-1px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        transform: "translate(0%, 0%)",
      };  

    const styleFondo = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        left: "50%",
        width: '100%',
        transform: "translate(-50%, 400%)",
        zIndex: "0"
      };     

    const interactivitySeccion5 = {
        mode: "scroll",
        actions: [
            {
                visibility: [0, 0.35],
                type: "stop",
                frames: [0],
            },
            {
                visibility: [0.35, 0.45],
                type: "play",
                frames: [0, 65],
            }
        ],
    };

    return (
        <Fragment>
            <Lottie 
                loop="false"
                autoplay
                animationData={animationData}
                interactivity={interactivitySeccion5}
                style={style}
            />
            <Lottie 
                loop
                autoplay
                animationData={animationFondo}
                style={styleFondo}
            />  
            <img id="fonddoSeccion5" src="./img/fondo.jpg" style={styleImage}></img>

            
            <object id="textoRegalo" type="image/svg+xml" data="./img/Regalo.svg"></object>

        </Fragment>

    );
}
 
export default Seccion_5