import React, {Fragment} from 'react'

import "./Funnel.css"

const Funnel = (props) => {
    return ( 
        <Fragment>
            <footer id="sticky-footer" >

                    <a href="https://api.whatsapp.com/send?phone=34747486791&text=Hola,%20tengo%20dudas%20sobre%20el%20modelo%2056" target="_blank">
                    <button type="button" class="btn btn-labeled btn-warning">
                        <span class="btn-label"><i class="fa fa-question-circle"></i></span>Tienes dudas?
                    </button>
                    </a>
                    
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    
                    <a href="https://www.acoloresdesign.es/product-page/invitaci%C3%B3n-aplicaci%C3%B3n-m%C3%BAsica-animaciones-foto-rsvp-maps-itinerario-4" target="_self">
                    <button type="button" class="btn btn-labeled btn-success">
                        <span class="btn-label"><i class="fa fa-shopping-cart"></i></span>&nbsp;&nbsp;Comprar&nbsp;&nbsp;&nbsp;&nbsp;
                    </button>
                    </a>

            </footer>
        </Fragment>                  
    );
}
 
export default Funnel;