import React, {Fragment} from 'react';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_6/data.json';

import "./Seccion_6.css"

const Seccion_6 = (props) => {

    let styleImage = {};

    if (props.ancho > 600) {
      styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 500%)",
        zIndex: "-100"
        };
    } else {
      styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        width: "100vw",
        position: "absolute",
        margin: "auto",
        
        transform: "translate(-50%, 500%)",
        zIndex: "-100"
        };
    }

    const style = {
        top: 0,
        margin: '-1px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        transform: "translate(0%, 0%)",
      };  

    return (
        <Fragment>
            <Lottie 
                loop
                autoplay
                animationData={animationData}
                style={style}
            /> 
            <img id="fonddoSeccion6" src="./img/fondo2.jpg" style={styleImage}></img>

            <object id="texto6" type="image/svg+xml" data="./img/Itinerario.svg"></object>
        </Fragment>

    );
}
 
export default Seccion_6