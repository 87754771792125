import React, {Fragment} from 'react';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_8/data.json';
import animationFlores from '../../data/Seccion_8_Flores/data.json';
import animationFondo from '../../data/Seccion_8_Fondo/data.json';

import Timer from '../Countdown/Timer';

import "./Seccion_8.css"

const Seccion_8 = (props) => {

  let styleImage = {};

  if (props.ancho > 600) {
    styleImage = {
      top: 0,
      padding: 0,
      border: 0,
      height: "100vh",
      position: "absolute",
      margin: "auto",
      transform: "translate(-50%, 700%)",
      zIndex: "-100"
      };
  } else {
    styleImage = {
      top: 0,
      padding: 0,
      border: 0,
      height: "100vh",
      width: "100vw",
      position: "absolute",
      margin: "auto",
      transform: "translate(-50%, 700%)",
      zIndex: "-100"
      };
  }

    const styleData = {
        top: 0,
        margin: '0px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: "100vw",
        transform: "translate(0%, 0%)",
        zIndex: "100"
      };  

      const styleFlores = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        left: "50%",
        width: '100%',
        transform: "translate(-50%, 700%)",
        zIndex: "200"
      }; 

      const styleFondo = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        left: "50%",
        width: '100%',
        transform: "translate(-50%, 700%)",
        zIndex: "0"
      };   

      const interactivitySeccion8 = {
        mode: "scroll",
        actions: [
            {
                visibility: [0, 0.35],
                type: "stop",
                frames: [0],
            },
            {
                visibility: [0.35, 0.45],
                type: "play",
                frames: [0, 65],
            }
        ],
      }; 


      const current = new Date();
      // it adds 15 days to a current date
      current.setDate(current.getDate()+17);
      //console.log(current);
      let ano = current.getUTCFullYear();
      let mes = current.getMonth() + 1;
      if(mes < 10) {
          mes = "0"+mes
      }
  
      let dia = current.getDate();
      console.log(current.getDate());
      if(dia < 10) {
          dia = "0"+dia
      }
      const fecha = ano+"-"+mes+"-"+dia+"T12:00:00.000Z";
      console.log(fecha);      

    return (
        <Fragment>
            <Lottie 
                  loop="false"
                  autoplay
                  animationData={animationFlores}
                  style={styleFlores}
                  interactivity={interactivitySeccion8}
              />    
            <Lottie 
                loop="false"
                autoplay
                animationData={animationData}
                style={styleData}
            />
            <Lottie 
                loop
                autoplay
                animationData={animationFondo}
                style={styleFondo}
            />      
                   
            <img id="fonddoSeccion8" src="./img/fondo2.jpg" style={styleImage}></img> 


            <div id="textoSeccion8">
                  <img src="./img/Contador.svg" alt=""/>
                  <div id="contenedorTimer" >
                    <Timer deadline={"2023-09-01T00:00:00.000Z"} />
                    {/* <Timer deadline={fecha} /> */}
                  </div>
            </div> 


        </Fragment>

    );
}
 
export default Seccion_8