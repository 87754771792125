import React, {useState, useEffect, useRef, useContext, Fragment} from 'react';

import MensajeContexto from '../Mensaje/MensajeContexto';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_2/data.json';
import animationFlores from '../../data/Seccion_2_Flores/data.json';
import animationFondo from '../../data/Seccion_2_Fondo/data.json';

import "./Seccion_2.css"

const Seccion_2 = (props) => {

    const [altura, setAltura] = useState(0)
    const [ancho, setAncho]   = useState(0)
  
    useEffect(() => {
        setAltura( window.innerHeight )
        setAncho( window.innerWidth )
    }, [altura, ancho])

    const refSeccion2 = useRef(null);

    const [animacion, setAnimacion] = useState(false);

    const { mostrarMensaje, updateMostrarMensaje } = useContext(MensajeContexto);

    const scrollCallback = (entries) => {
        if (entries[0].isIntersecting) {
            updateMostrarMensaje(false);
            setAnimacion(true);
        }else{
            // do nothing
        }
    };

    useEffect(() => {
        // *** Grab the element related to this callback
        const { current } = refSeccion2;
        const observer = new IntersectionObserver(scrollCallback, {
            root: null,
            threshold: 1,
        });
        observer.observe(current);
        return () => {
            observer.disconnect(current); // *** Use the same element
        }
    }, [refSeccion2.current]); // *** Note dependency


    let styleImage = {};

    if (props.ancho > 600) {
      styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 100%)",
        zIndex: "-100"
        };
    } else {
      styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        width: "100vw",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 100%)",
        zIndex: "-100"
        };
    }

    const styleData = {
        top: 0,
        margin: '0px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: "100vw",
        transform: "translate(0%, 0%)",
        zIndex: "100"
      };  

      const styleFlores = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        left: "50%",
        width: '100%',
        transform: "translate(-50%, 100%)",
        zIndex: "200"
      }; 

      const styleFondo = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        left: "50%",
        width: '100%',
        transform: "translate(-50%, 100%)",
        zIndex: "0"
      };   

      const styleBox = {
        top: 0,
        padding: 0,
        border: 0,
        "background-color": "transparent",
        width: "100px",
        height: "100px",
        position: "absolute",
        margin: "auto",
        left:"50%",
        transform: "translate(-50%, 1000%)",
        zIndex: "-1000"
    };

    const interactivitySeccion2 = {
        mode: "scroll",
        actions: [
            {
                visibility: [0, 0.35],
                type: "stop",
                frames: [0],
            },
            {
                visibility: [0.35, 0.45],
                type: "play",
                frames: [0, 65],
            }
        ],
    };

    return (
        <Fragment>
            <Lottie 
                  loop="false"
                  autoplay
                  animationData={animationFlores}
                  style={styleFlores}
                  interactivity={interactivitySeccion2}
              />    
            <Lottie 
                loop="false"
                autoplay
                animationData={animationData}
                style={styleData}
            />
            <Lottie 
                loop
                autoplay
                animationData={animationFondo}
                style={styleFondo}
            />  

            <img id="fonddoSeccion2" src="./img/fondo2.jpg" style={styleImage}></img>

            <div id="seccion2Box" ref={refSeccion2} style={styleBox}></div>

        </Fragment>
    );
}
 
export default Seccion_2