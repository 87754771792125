import React, {Fragment, useContext} from 'react';

import MensajeContexto from '../Mensaje/MensajeContexto';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_1/data.json';
import animationFlores from '../../data/Seccion_1_Flores/data.json';
import animationFondo from '../../data/Seccion_1_Fondo/data.json';

import "./Seccion_1.css"

const Seccion_1 = (props) => {

    const { mostrarMensaje, updateMostrarMensaje } = useContext(MensajeContexto);

    let styleImage = {};

    if (props.ancho > 600) {
      styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 0%)",
        zIndex: "-100"
        };
    } else {
      styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        width: "100vw",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 0%)",
        zIndex: "-100"
        };
    }
  
    const styleData = {
        top: 0,
        margin: '0px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: "100vw",
        transform: "translate(0%, 0%)",
        zIndex: "100"
      };  

      const styleFlores = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        left: "50%",
        width: '100%',
        transform: "translate(-50%, 0%)",
        zIndex: "200"
      }; 

      const styleFondo = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        left: "50%",
        width: '100%',
        transform: "translate(-50%, 0%)",
        zIndex: "0"
      };     

      const mensaje = () => {
        const timeout = setTimeout(() => {
          updateMostrarMensaje(true);
        }, 8000)      
      }    

    return (
        <Fragment>
            <Lottie 
                  loop="false"
                  autoplay
                  animationData={animationFlores}
                  style={styleFlores}
                  onLoadedImages = {mensaje}
              />    
            <Lottie 
                loop="false"
                autoplay
                animationData={animationData}
                style={styleData}
            />
            <Lottie 
                loop
                autoplay
                animationData={animationFondo}
                style={styleFondo}
            />             
            <img id="fonddoSeccion8" src="./img/fondo.jpg" style={styleImage}></img> 

            {mostrarMensaje ? <div>
                                  <div class="scroll-up"></div>
                                  <div class="scroll-up2"></div>
                                  <div class="scroll-up3"></div>
                              </div>
                : "" } 
        </Fragment>
    );
}
 
export default Seccion_1